import React, { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { checkToolStatus, checkToolPermission } from '../../../api/tools'
import { useInterval } from '../../../hooks'

const StyledStatus = styled.p`
  font-size: 16px;
  padding: 3px 12px;
  text-align: center;
  border-radius: 5px;
  font-weight: bold;
  text-transform: uppercase;
  color: ${props => props.theme.colors.primary};
  ${props =>
    props.status === 'Offline' && `background: ${props.theme.colors.danger};`}
  ${props =>
    props.status === 'Unauthorized' &&
    `background: ${props.theme.colors.warning};`}
  ${props =>
    props.status === 'Online' && `background: ${props.theme.colors.success};`}
`

function ToolStatus({ url, status, setStatus, setVersion }) {
  const statusMessage = () => {
    switch (status) {
      case 'online':
        return 'Online'
      case 'unauthorized':
        return 'Unauthorized'
      default:
        return 'Offline'
    }
  }
  const fetchStatus = useCallback(async () => {
    try {
      const version = await checkToolStatus(url)
      await checkToolPermission(url)

      setStatus('online')
      // Temporary check until all tools actually return the version rather than the tools gateway homepage
      if (!version.toLowerCase().includes('doctype')) {
        setVersion(version)
      }
    } catch (e) {
      const { message = '' } = e
      if (message === '403') {
        setStatus('unauthorized')
      } else {
        setStatus('offline')
      }
    }
  }, [setStatus, setVersion, url])

  useEffect(() => {
    fetchStatus()
  }, [fetchStatus])

  useInterval(() => {
    fetchStatus()
  }, 120000)

  return (
    <StyledStatus status={statusMessage()}>
      <span>{statusMessage()}</span>
    </StyledStatus>
  )
}

ToolStatus.propTypes = {
  url: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  setStatus: PropTypes.func.isRequired,
  setVersion: PropTypes.func.isRequired,
}

export default ToolStatus
